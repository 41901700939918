import LoginHeader from "./LoginHeader";
import LoginFooter from "./LoginFooter";
import "./index.css";

const LeftSection = ({
  email,
  password,
  setEmail,
  setPassword,
  handleLogin,
  loading,
  error,
}) => {
  return (
    <div className="flex items-center flex-col w-full justify-between h-screen">
      <LoginHeader />
      <div className="container flex justify-center items-center ">
        <div className="login-form">
          <div className="py-5 text-left flex flex-col space-y-2 w-[20rem]">
            <h1 className="font-[500] text-[1.875rem]">Sign in</h1>
            <p className="text-[#475467] text-[1rem] text-light">
              Welcome back! Please enter your details.
            </p>
          </div>
          <div className="flex flex-col space-y-5 w-[20rem]">
            <div className="container flex flex-col space-y-1">
              <lable for="email" className="lable text-[0.9rem]">
                Email
              </lable>
              <input
                type="email"
                value={email}
                placeholder="Enter your Email"
                onInput={(e) => setEmail(e.target.value)}
                className="input input-focus" 
              />
            </div>
            <div className="container flex flex-col space-y-1">
              <lable for="email" className="lable text-[0.9rem]">
                Password
              </lable>
              <input
                type="password"
                value={password}
                placeholder="Enter your Password"
                onInput={(e) => setPassword(e.target.value)}
                className="input input-focus"
              />
            </div>

            <div className="flex justify-between">
              <div className="flex space-x-3">
                <input type="checkbox" className="p-5" />
                <label for="checkbox" className="text-[14px] text-[#344054]">
                  Remember for 30 days
                </label>
              </div>
              <div className="flex space-x-3">
                <a
                  href="/forget_password"
                  className="text-[#0439D7] font-light text-[14px] hover:underline"
                >
                  Forget password
                </a>
              </div>
            </div>
            <div className="flex justify-between w-full">
              <div className="flex space-x-3 w-full mt-5 flex-col text-center">
                <button
                  onClick={handleLogin}
                  className="w-full bg-[#0439D7] rounded p-2 text-white"
                >
                  {loading ? (
                    <div
                      className="inline-block h-4 w-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                      role="status"
                    >
                      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                        Loading...
                      </span>
                    </div>
                  ) : (
                    "Sign in"
                  )}
                </button>
                {error && <p className="text-red-500 mt-5">{error}</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoginFooter />
    </div>
  );
};

export default LeftSection;
