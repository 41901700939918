import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./pages/Login";
import StatsDashboard from "./pages/StatsDashboard";
import CustomersDashboard from "./pages/CustomersDashboard";
import Profile from "./pages/Profile";
import Chat from "./pages/Chat";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/profile/:id" component={Profile} />
        <Route path="/login" component={Login} />
        <Route path="/customers" component={CustomersDashboard} />
        <Route path="/chat/:id" component={Chat} />
        <Route path="/" component={StatsDashboard} />
      </Switch>
    </Router>
  );
}

export default App;
