import SideBar from "../components/DashboardComponents/SideBar";
import Header from "../components/StatsDashboard/Header";
import Stats from "../components/StatsDashboard/Stats";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useState, useEffect } from "react";
import { isTokenExpired } from "../services/AuthApi";

const StatsDashboard = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const isUserNotLogin = isTokenExpired();
    if (isUserNotLogin) {
      history.push("/login");
      window.dispatchEvent(new Event("popstate"));
    }
  }, [history]);

  return (
    <div className="flex p-0 w-full">
      <SideBar />
      <div className="flex flex-col w-full" style={{ marginLeft: "4rem" }}>
        <Header />
        <Stats isloading={loading} setLoading={setLoading} />
      </div>
    </div>
  );
};

export default StatsDashboard;
