import SideBar from "../components/DashboardComponents/SideBar";
import ProfileHeader from "../components/ProfileComponents/Header";
import ProfileStats from "../components/ProfileComponents/ProfileStats";
import ProductForm from "../components/ProfileComponents/ProductForm";
import ProfileInfoForm from "../components/ProfileComponents/ProfileInfoForm";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
import { useContext } from "react";
import { getCustomerInfo } from "../services/AuthApi";

import {
  fetchSingleLoanApplication,
  getUserRevenueCalculations,
} from "../services/api";
import { isTokenExpired } from "../services/AuthApi";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { LoanApplicationContext } from "../LoanApplication";

const Profile = () => {
  const { id } = useParams();
  const [loanApplication, setLoanApplication] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [revenueData, setRevenueData] = useState(null);
  const history = useHistory();
  const { setLoanApplicationId } = useContext(LoanApplicationContext);
  const [customerInfo, setCustomerInfo] = useState(null);

  useEffect(() => {
    setLoanApplicationId(id);
  }, [id, setLoanApplicationId]);

  useEffect(() => {
    const isUserNotLogin = isTokenExpired();
    if (isUserNotLogin) {
      history.push("/login");
      window.dispatchEvent(new Event("popstate"));
    } else {
      getCustomer();
    }
  }, []);

  const getCustomer = async () => {
    try {
      const response = await getCustomerInfo(id);
      setCustomerInfo(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch products", error);
      setLoading(false);
    }
  };

  const getUserRevenueCalculation = async () => {
    try {
      const response = await getUserRevenueCalculations(customerInfo.id);
      setRevenueData(response.data);
    } catch (error) {
      console.log("Error", error);
    } finally {
    }
  };

  useEffect(() => {
    getUserRevenueCalculation();
  }, [customerInfo]);

  return (
    <div className="flex p-0 ">
      <div className="flex flex-col w-full " style={{ marginLeft: "4rem" }}>
        <ProfileHeader
          id={customerInfo ? customerInfo.id : null}
          isprofile={true}
          firstName={customerInfo ? customerInfo.first_name : null}
          lastName={customerInfo ? customerInfo.last_name : null}
          loanId={customerInfo ? customerInfo.id : null}
        />
        {error ? <p className="bf-red-500">{{ error }}</p> : ""}
        <div className="max-w-[1440px] mx-auto">
          <ProfileStats revenueData={revenueData} />
          <ProductForm userId={customerInfo ? customerInfo?.id : null} />
          <ProfileInfoForm
            userInfo={customerInfo}
            loading={loading}
            setLoading={setLoading}
            refresh={getCustomer}
          />
        </div>
      </div>
      <SideBar />
      <ToastContainer
        type="success"
        promise="true"
        position="top-right"
        autoClose="3000"
        theme="colored"
      />
    </div>
  );
};

export default Profile;
