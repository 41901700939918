import SideBar from "../components/DashboardComponents/SideBar";
import Header from "../components/DashboardComponents/Header";
import Table from "../components/DashboardComponents/Table";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useState, useEffect, useCallback } from "react";
import { isTokenExpired } from "../services/AuthApi";
import { fetchLoanApplications } from "../services/api";

const CustomersDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [loadApplications, setLoanApplications] = useState([]);
  const [previousLink, setPreviousLink] = useState(null);
  const [nextLink, setNextLink] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [queryString, setQueryString] = useState({
    page: 1,
    search: "",
    status: "",
    source: "",
  });
  const history = useHistory();

  useEffect(() => {
    getLoanApplications(queryString);
  }, [queryString]);

  useEffect(() => {
    const isUserNotLogin = isTokenExpired();
    if (isUserNotLogin) {
      history.push("/login");
      window.dispatchEvent(new Event("popstate"));
    } else {
      getLoanApplications();
    }
  }, [history]);

  const getLoanApplications = async (queryString) => {
    try {
      setLoading(true);
      const params = new URLSearchParams(queryString).toString();
      const response = await fetchLoanApplications(params);
      setLoading(false);
      setLoanApplications(response.data.results);
      setPreviousLink(response.data.links.previous);
      setNextLink(response.data.links.next);
      setTotalPages(response.data.total_pages);
    } catch (error) {
      setError("Failed to fetch products. Please try again later.");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex p-0 w-full">
      <SideBar />
      <div className="flex flex-col w-full" style={{ marginLeft: "4rem" }}>
        <Header />

        <div className="min-w-[1440pxpx] 2xl:mx-auto px-5 py-[20px]">
          <div className="flex text-left border-b border-[#EAECF0]">
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.8125 21.5625C5.8125 25.8 9.2625 29.25 13.5 29.25C14.8875 29.25 16.2375 28.875 17.4 28.1625C17.925 27.8625 18.1125 27.15 17.775 26.625C17.475 26.1 16.7625 25.9125 16.2375 26.25C15.4125 26.7375 14.4375 27 13.4625 27C10.4625 27 8.025 24.5625 8.025 21.5625C8.025 18.7125 10.2375 16.35 13.0875 16.1625C13.725 16.125 14.175 15.6 14.1375 14.9625C14.1 14.325 13.575 13.875 12.9375 13.9125C8.9625 14.1375 5.8125 17.5125 5.8125 21.5625Z"
                fill="black"
                h-full
              />
              <path
                d="M0.787486 40.7249C0.974986 40.8374 1.16249 40.8749 1.34999 40.8749C1.72499 40.8749 2.13749 40.6874 2.32499 40.3124C3.97499 37.3874 6.78749 35.1749 10.05 34.2749C10.65 34.1249 10.9875 33.4874 10.8375 32.8874C10.6875 32.2874 10.05 31.9499 9.44999 32.0999C5.62499 33.1874 2.32499 35.7749 0.374986 39.1874C0.074986 39.7499 0.262486 40.4249 0.787486 40.7249Z"
                fill="black"
              />
              <path
                d="M42.1875 21.5625C42.1875 17.55 39.0375 14.175 35.025 13.9125C34.425 13.875 33.8625 14.325 33.825 14.9625C33.7875 15.6 34.2375 16.125 34.875 16.1625C37.725 16.35 39.9375 18.7125 39.9375 21.5625C39.9375 24.5625 37.5 27 34.5 27C33.525 27 32.55 26.7375 31.725 26.25C31.2 25.95 30.4875 26.1 30.1875 26.625C29.8875 27.15 30.0375 27.8625 30.5625 28.1625C31.725 28.875 33.1125 29.25 34.4625 29.25C38.7375 29.2125 42.1875 25.8 42.1875 21.5625Z"
                fill="black"
              />
              <path
                d="M38.55 32.0999C37.95 31.9499 37.3125 32.2874 37.1625 32.8874C37.0125 33.4874 37.35 34.1249 37.95 34.2749C41.175 35.1749 43.9875 37.3874 45.675 40.3124C45.9 40.6874 46.275 40.8749 46.65 40.8749C46.8375 40.8749 47.025 40.8374 47.2125 40.7249C47.7375 40.4249 47.925 39.7499 47.625 39.1874C45.675 35.7749 42.375 33.1874 38.55 32.0999Z"
                fill="black"
              />
              <path
                d="M24 10.125C19.2375 10.125 15.375 13.9875 15.375 18.75C15.375 23.5125 19.2375 27.375 24 27.375C28.7625 27.375 32.625 23.5125 32.625 18.75C32.625 13.9875 28.7625 10.125 24 10.125ZM24 25.125C20.475 25.125 17.625 22.275 17.625 18.75C17.625 15.225 20.475 12.375 24 12.375C27.525 12.375 30.375 15.225 30.375 18.75C30.375 22.275 27.525 25.125 24 25.125Z"
                fill="black"
              />
              <path
                d="M9.56251 40.725C9.75001 40.8375 9.93751 40.875 10.125 40.875C10.5 40.875 10.9125 40.6875 11.1 40.3125C13.725 35.6625 18.675 32.775 24.0375 32.775C29.3625 32.775 34.3125 35.6625 36.975 40.3125C37.275 40.8375 37.95 41.025 38.5125 40.725C39.0375 40.425 39.225 39.75 38.925 39.1875C35.85 33.8625 30.15 30.525 24 30.525C17.85 30.525 12.15 33.8625 9.11251 39.1875C8.81251 39.75 9.00001 40.425 9.56251 40.725Z"
                fill="black"
              />
            </svg>
            <div className="ml-2 mb-5">
              <p className="text-[#101828] font-[500] mt-[4px]">Customers</p>
              <p className="text-[#475467] font-light ">
                All Coverzen customers
              </p>
            </div>
          </div>

          <Table
            loanApplications={
              loadApplications.length > 0 ? loadApplications : []
            }
            previousLink={previousLink}
            nextLink={nextLink}
            totalPages={totalPages}
            fetchLoanApplication={getLoanApplications}
            queryStr={queryString}
            setQueryStr={setQueryString}
            loading={loading}
            setLoading={setLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomersDashboard;
