import logo from "./images/logo.jpeg";
import Profile from "./Profile";
import { useState, useEffect } from "react";
import { isTokenExpired } from "../../services/AuthApi";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getUserInfo } from "../../services/AuthApi";

const SideBar = () => {
  const [firstName, setFirstname] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [isOpenSideBar, setOpenSideBar] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const isUserNotLogin = isTokenExpired();
    if (isUserNotLogin) {
      history.push("/login");
      window.dispatchEvent(new Event("popstate"));
    } else {
      handlegetUserInfo();
    }
  }, [history]);

  const handlegetUserInfo = async () => {
    try {
      const response = await getUserInfo();
      setEmail(response.data.email);
      setFirstname(response.data.first_name);
      setLastName(response.data.last_name);
      setProfileImage(response.data.profile_image);
    } catch (error) {
      console.error("Failed to fetch user Info", error);
    } finally {
    }
  };

  const Logout = () => {
    localStorage.setItem("AccessToken", "");
    history.push("/login");
  };

  return (
    <>
      <button
        data-drawer-target="separator-sidebar"
        data-drawer-toggle="separator-sidebar"
        aria-controls="separator-sidebar"
        type="button"
        class="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
      >
        <span class="sr-only">Open sidebar</span>
        <svg
          class="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clip-rule="evenodd"
            fill-rule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>

      <aside
        id="separator-sidebar"
        // onMouseOver={() => setOpenSideBar(true)}
        // onMouseOut={() => setOpenSideBar(false)}
        class="fixed flex flex-col justify-between h-full top-0 left-0 z-40 w-16 h-screen transition-transform -translate-x-full sm:translate-x-0 border-r border-gray-300 h-full"
        aria-label="Sidebar"
      >
        <div class="h-full px-3 py-4 overflow-y-auto bg-light-50 ">
          <ul class="space-y-4 font-medium ">
            {!isOpenSideBar && (
              <li
                className="flex justify-center"
                onClick={() => setOpenSideBar(true)}
              >
                {/* first svg */}
                <div className="rounded hover:bg-slate-100 cursor-pointer p-2">
                  <svg
                    width="17"
                    height="18"
                    viewBox="0 0 17 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.799988 9.00001C0.799988 9.44184 1.15816 9.80001 1.59999 9.80001H12.4686L8.23431 14.0343C7.92188 14.3467 7.92188 14.8533 8.23431 15.1658C8.54672 15.4781 9.05325 15.4781 9.36567 15.1658L14.9657 9.56569C15.1217 9.40968 15.1998 9.20528 15.2 9.0008V17C15.2 17.4418 15.5582 17.8 16 17.8C16.4417 17.8 16.8 17.4418 16.8 17V1.00001C16.8 0.558188 16.4417 0.200012 16 0.200012C15.5582 0.200012 15.2 0.558188 15.2 1.00001V9.0008C15.1998 8.79632 15.1217 8.59033 14.9657 8.43432L9.36567 2.83433C9.05325 2.5219 8.54672 2.5219 8.23431 2.83433C7.92188 3.14675 7.92188 3.65328 8.23431 3.96569L12.4686 8.20001H1.59999C1.15816 8.20001 0.799988 8.55819 0.799988 9.00001Z"
                      fill="#667085"
                    />
                  </svg>
                </div>
              </li>
            )}

            {isOpenSideBar && (
              <li
                className="flex justify-center"
                onClick={() => setOpenSideBar(false)}
              >
                {/* second svg */}
                <div className="rounded hover:bg-slate-100 cursor-pointer p-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M19.2 12C19.2 12.4418 18.8418 12.8 18.4 12.8H7.53138L11.7657 17.0343C12.0781 17.3467 12.0781 17.8533 11.7657 18.1658C11.4533 18.4781 10.9467 18.4781 10.6343 18.1658L5.03432 12.5657C4.8783 12.4097 4.80019 12.2053 4.8 12.0008V20C4.8 20.4418 4.44182 20.8 4 20.8C3.55818 20.8 3.2 20.4418 3.2 20V4.00001C3.2 3.55819 3.55818 3.20001 4 3.20001C4.44182 3.20001 4.8 3.55819 4.8 4.00001V12.0008C4.80019 11.7963 4.8783 11.5903 5.03432 11.4343L10.6343 5.83433C10.9467 5.5219 11.4533 5.5219 11.7657 5.83433C12.0781 6.14675 12.0781 6.65328 11.7657 6.96569L7.53138 11.2H18.4C18.8418 11.2 19.2 11.5582 19.2 12Z"
                      fill="#667085"
                    />
                  </svg>
                </div>
              </li>
            )}

            <li>
              <a
                href="/"
                class="flex items-center justify-center p-1.5 text-gray-900 rounded-lg  hover:bg-slate-100  group"
              >
                <svg
                  width="34"
                  height="32"
                  viewBox="0 0 30 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.0411 10.2814C20.0411 5.29823 14.8419 1 14.8419 1C9.88734 4.93066 9.86584 10.2814 9.86584 10.2814"
                    stroke="#CCA43D"
                    stroke-width="1.02388"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M19.6183 10.3172C19.6183 10.3172 24.9691 10.3397 28.8997 15.2932C28.8997 15.2932 24.5994 20.4925 19.6183 20.4925"
                    stroke="#CCA43D"
                    stroke-width="1.02388"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M20.0411 20.1249C20.0411 20.1249 20.0186 25.4757 15.0641 29.4064C15.0641 29.4064 9.86584 25.1061 9.86584 20.1249"
                    stroke="#CCA43D"
                    stroke-width="1.02388"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M10.2815 20.4925C10.2815 20.4925 4.93072 20.47 1.00006 15.5154C1.00006 15.5154 5.30034 10.3172 10.2815 10.3172"
                    stroke="#CCA43D"
                    stroke-width="1.02388"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M14.9207 8.17021C14.9207 8.17021 18.7091 4.39415 24.9957 5.10472C24.9957 5.10472 25.6468 11.8183 22.1288 15.3496"
                    stroke="#CCA43D"
                    stroke-width="1.02388"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M21.8063 15.0772C21.8063 15.0772 25.5823 18.8656 24.8779 25.1522C24.8779 25.1522 18.1633 25.8033 14.632 22.2853"
                    stroke="#CCA43D"
                    stroke-width="1.02388"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M15.1858 22.3263C15.1858 22.3263 11.3974 26.1023 5.11081 25.3918C5.11081 25.3918 4.4586 18.6782 7.97766 15.1469"
                    stroke="#CCA43D"
                    stroke-width="1.02388"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M8.00959 15.6998C8.00959 15.6998 4.23251 11.9115 4.94308 5.62491C4.94308 5.62491 11.6566 4.9727 15.188 8.49176"
                    stroke="#CCA43D"
                    stroke-width="1.02388"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M19.9315 17.4383L22.0049 15.4734L19.9315 13.2844V10.2681H17.0749L15.1101 8.1947L12.921 10.2681H9.90471V13.1247L7.83136 15.0895L9.90471 17.2785V20.2949H12.7623L14.7261 22.3682L16.9162 20.2949H19.9315V17.4383Z"
                    stroke="#CCA43D"
                    stroke-width="1.02388"
                    stroke-miterlimit="10"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a
                href="/"
                class="flex items-center p-2 text-gray-900 rounded-lg  hover:bg-slate-100  group"
              >
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {" "}
                  <path
                    d="M6 16H14M9.0177 1.764L2.23539 7.03912C1.78202 7.39175 1.55534 7.56806 1.39203 7.78886C1.24737 7.98444 1.1396 8.20478 1.07403 8.43905C1 8.70352 1 8.9907 1 9.56505V16.8C1 17.9201 1 18.4801 1.21799 18.908C1.40973 19.2843 1.71569 19.5903 2.09202 19.782C2.51984 20 3.07989 20 4.2 20H15.8C16.9201 20 17.4802 20 17.908 19.782C18.2843 19.5903 18.5903 19.2843 18.782 18.908C19 18.4801 19 17.9201 19 16.8V9.56505C19 8.9907 19 8.70352 18.926 8.43905C18.8604 8.20478 18.7526 7.98444 18.608 7.78886C18.4447 7.56806 18.218 7.39175 17.7646 7.03913L10.9823 1.764C10.631 1.49075 10.4553 1.35412 10.2613 1.3016C10.0902 1.25526 9.9098 1.25526 9.73865 1.3016C9.54468 1.35412 9.36902 1.49075 9.0177 1.764Z"
                    stroke="#667085"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />{" "}
                </svg>
              </a>
            </li>
            <li>
              <a
                href="/customers"
                class="flex items-center text-gray-900 rounded-lg hover:bg-slate-100 group p-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    d="M22 21.4064V19.4064C22 17.5426 20.7252 15.9765 19 15.5325M15.5 3.69719C16.9659 4.29058 18 5.72774 18 7.40643C18 9.08512 16.9659 10.5223 15.5 11.1157M17 21.4064C17 19.5427 17 18.6108 16.6955 17.8757C16.2895 16.8956 15.5108 16.1169 14.5307 15.7109C13.7956 15.4064 12.8638 15.4064 11 15.4064H8C6.13623 15.4064 5.20435 15.4064 4.46927 15.7109C3.48915 16.1169 2.71046 16.8956 2.30448 17.8757C2 18.6108 2 19.5427 2 21.4064M13.5 7.40643C13.5 9.61557 11.7091 11.4064 9.5 11.4064C7.29086 11.4064 5.5 9.61557 5.5 7.40643C5.5 5.19729 7.29086 3.40643 9.5 3.40643C11.7091 3.40643 13.5 5.19729 13.5 7.40643Z"
                    stroke="#667085"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </div>
        <div className="flex justify-center flex-col space-y-4 items-center mb-2">
          <a
            href="#"
            class="flex items-center p-2 text-gray-900 rounded-lg  hover:bg-slate-100  group"
          >
            <Profile profileImage={profileImage} />
          </a>
        </div>

        <div
          className={`absolute container-fluid w-[22vw] h-full p-5 shadow h-full bg-white space-y-4 transform transition-transform duration-300 ${
            isOpenSideBar ? "-translate-x-0 ml-16" : "-translate-x-full ml-0"
          }`}
        >
          <div className="container">
            <div>
              <p>Dashboard</p>
            </div>
          </div>
          <div className="container h-full flex flex-col justify-between">
            <div>
              <ul className="container">
                <a href="/" className="flex hover:bg-slate-100 p-2 rounded">
                  <li className="flex gap-2 bg-transparent">
                    <svg
                      class="h-6 w-6 text-neutral-500"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      {" "}
                      <path stroke="none" d="M0 0h24v24H0z" />{" "}
                      <polyline points="21 12 17 12 14 20 10 4 7 12 3 12" />
                    </svg>
                    Overview
                  </li>
                </a>
              </ul>
            </div>
            <div className="container mb-10 flex">
              <div className="container">
                <p className="font-[500]">
                  {firstName} {lastName}
                </p>
                <p className="text-gray-600">{email}</p>
              </div>
              <div>
                <button
                  onClick={Logout}
                  className="w-full flex rounded hover:bg-slate-100 p-2 cursor-pointer"
                >
                  <svg
                    class="h-6 w-6 text-gray-500"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    {" "}
                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />{" "}
                    <polyline points="16 17 21 12 16 7" />{" "}
                    <line x1="21" y1="12" x2="9" y2="12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </>
  );
};

export default SideBar;
