import axios from "axios";
import baseUrl from "./config";

const API_URL = `${baseUrl}/api`;

/**
 * Fetches products all loan applications from the API with authorization.
 * @returns {Promise} Axios promise representing the fetch request.
 */

export const fetchLoanApplications = (params) => {
  const accessToken = localStorage.getItem("AccessToken");

  return axios.get(`${API_URL}/loan_applications/?${params}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

/**
 * Fetches single loan application from the API with authorization.
 * @returns {Promise} Axios promise representing the fetch request.
 */

export const fetchSingleLoanApplication = (id) => {
  const accessToken = localStorage.getItem("AccessToken");

  return axios.get(`${API_URL}/loan_application/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

/**
 * Fetches chats data from the API with authorization.
 * @returns {Promise} Axios promise representing the fetch request.
 */

export const fetchChats = () => {
  const accessToken = localStorage.getItem("AccessToken");

  return axios.get(`${API_URL}/chats`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

/**
 * Fetches chats data from the API with authorization.
 * @returns {Promise} Axios promise representing the fetch request.
 */

export const fetchCustomersCount = () => {
  const accessToken = localStorage.getItem("AccessToken");

  return axios.get(`${API_URL}/accounts/get/customers/count`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

/**
 * Fetches chats data from the API with authorization.
 * @returns {Promise} Axios promise representing the fetch request.
 */

export const fetchCustomersProducts = (user_id) => {
  const accessToken = localStorage.getItem("AccessToken");

  return axios.get(`${API_URL}/accounts/get/customer/products/${user_id}/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

/**
 * Fetches chats data from the API with authorization.
 * @returns {Promise} Axios promise representing the fetch request.
 */
export const updateCustomerProductsStatus = (id, status) => {
  const accessToken = localStorage.getItem("AccessToken");

  return axios.put(
    `${API_URL}/accounts/update/customer/products/status/${id}/`,
    { status }, // Send status as part of the request body
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json", // Ensure the Content-Type header is set for JSON payload
      },
    }
  );
};

/**
 * Fetches chats data from the API with authorization.
 * @returns {Promise} Axios promise representing the fetch request.
 */
export const getRevenueCalculations = () => {
  const accessToken = localStorage.getItem("AccessToken");

  return axios.get(`${API_URL}/calculate-revenue`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });
};

/**
 * Fetches chats data from the API with authorization.
 * @returns {Promise} Axios promise representing the fetch request.
 */
export const getUserRevenueCalculations = (user_id) => {
  const accessToken = localStorage.getItem("AccessToken");

  return axios.get(`${API_URL}/calculate-revenue/${user_id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });
};

/**
 * Fetches chats data from the API with authorization.
 * @returns {Promise} Axios promise representing the fetch request.
 */
export const getCategoriesCount = () => {
  const accessToken = localStorage.getItem("AccessToken");

  return axios.get(`${API_URL}/get/categories/count`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });
};

/**
 * Fetches chats data from the API with authorization.
 * @returns {Promise} Axios promise representing the fetch request.
 */
export const getAcquisitionData = (time_frame) => {
  const accessToken = localStorage.getItem("AccessToken");

  return axios.get(`${API_URL}/get/acquisitions/${time_frame}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });
};

/**
 * Fetches chats data from the API with authorization.
 * @returns {Promise} Axios promise representing the fetch request.
 */
export const getUserChat = (user_id, limit) => {
  const accessToken = localStorage.getItem("AccessToken");

  return axios.get(
    `${API_URL}/get/agent/conversation/${user_id}?limit=${limit}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );
};

/**
 * Fetches chats data from the API with authorization.
 * @returns {Promise} Axios promise representing the fetch request.
 */
export const sendMessage = (user_id, body) => {
  const accessToken = localStorage.getItem("AccessToken");

  return axios.post(
    `${API_URL}/send/agent/message/${user_id}`,
    { body }, // Correct way to include the body data
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );
};
