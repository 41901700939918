import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import IncomingMessages from "./IncomingMessage";
import OutGoingMessages from "./OutgoingMessages";
import { getCustomerInfo } from "../../services/AuthApi";
import { sendMessage } from "../../services/api";
import SendMessageInput from "./SendMessageInput";

const ChatComponent = ({
  messagelist,
  fetchChats,
  loading,
  requestInProgess,
  setRequestInProgess,
  setMessagelist,
  userInfo,
}) => {
  const [message, setMessage] = useState("");
  const messagesEndRef = useRef(null);
  const chatRef = useRef(null);
  const [limit, setLimit] = useState(10);
  const [lastIndex, setLastIndex] = useState(null);

  const [initialLoad, setInitialLoad] = useState(true);
  const { id } = useParams();

  const handleSendMessage = async () => {
    if (message.trim()) {
      const newMessage = {
        sid: "123144abc",
        body: message,
        type: "outgoing",
        date_created: new Date().toISOString(),
      };

      setMessagelist((prevMessageList) => ({
        ...prevMessageList,
        messages: [...prevMessageList.messages, newMessage],
      }));
      setTimeout(() => {
        document.getElementById("chat-container").scrollTop =
          document.getElementById("chat-container").scrollHeight;
      }, 200);

      document.getElementById("message-input").value = "";

      await sendWhatsappMessage(message);
    }
  };

  useEffect(() => {
    const loadChats = async () => {
      if (lastIndex === null || limit < lastIndex) {
        await fetchChats(id, limit);
      }
      setRequestInProgess(false);
    };

    loadChats();
  }, [id, limit]);

  useEffect(() => {
    if (lastIndex !== null && lastIndex !== 0) {
      if (requestInProgess) {
        fetchChats(id, limit);
      }
    } else {
    }
    setInitialLoad(false);
  }, [lastIndex, limit]);

  useEffect(() => {
    const lastMessageIndex = messagelist?.messages[0].index;
    setLimit((prev) => prev + 10);
    setLastIndex(lastMessageIndex);
  }, [requestInProgess]);

  useEffect(() => {
    const handleScroll = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (chatRef.current.scrollTop === 0 && !requestInProgess) {
        setRequestInProgess(true);

        document.getElementById("chat-container").scrollTop = 0.5;
      }
    };

    const chatElement = chatRef.current;
    chatElement.addEventListener("scroll", handleScroll);

    return () => {
      chatElement.removeEventListener("scroll", handleScroll);
    };
  }, [limit, lastIndex]);

  const sendWhatsappMessage = async (message) => {
    try {
      await sendMessage(id, message);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };
  const phoneNumber = userInfo?.whatsapp_phone_number?.match(/(\d+)/)[0];

  const handleClick = () => {
    window.open(`https://wa.me/${phoneNumber}`, "_blank");
  };

  return (
    <div className="flex flex-col bg- justify-center items-center bg-white container-fluid p-5">
      <div className="container flex justify-end items-center gap-1">
        <button
          className="border border-blue-600 bg-transparent px-5 py-3 hover:bg-blue-600 hover:text-white rounded text-blue-800 flex space-x-2"
          onClick={handleClick}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 mt-[2px]"
          >
            <path
              d="M11.7079 5.00002C12.5218 5.15882 13.2699 5.5569 13.8563 6.1433C14.4427 6.7297 14.8407 7.47774 14.9996 8.29169M11.7079 1.66669C13.399 1.85455 14.9759 2.61183 16.1798 3.81419C17.3836 5.01655 18.1429 6.59253 18.3329 8.28335M8.52204 11.5526C7.52072 10.5513 6.73007 9.41906 6.15007 8.21104C6.10018 8.10714 6.07523 8.05518 6.05607 7.98944C5.98797 7.75581 6.03689 7.46893 6.17856 7.27107C6.21843 7.21539 6.26606 7.16776 6.36132 7.0725C6.65266 6.78117 6.79833 6.6355 6.89356 6.48901C7.25273 5.93661 7.25273 5.22446 6.89356 4.67205C6.79833 4.52557 6.65266 4.3799 6.36132 4.08856L6.19893 3.92617C5.75606 3.4833 5.53462 3.26186 5.29681 3.14158C4.82384 2.90235 4.26529 2.90235 3.79232 3.14158C3.5545 3.26186 3.33307 3.4833 2.8902 3.92617L2.75883 4.05753C2.31748 4.49888 2.09681 4.71956 1.92827 5.01958C1.74125 5.35251 1.60678 5.86958 1.60792 6.25143C1.60894 6.59556 1.67569 6.83074 1.8092 7.30111C2.52668 9.82894 3.8804 12.2142 5.87039 14.2042C7.86037 16.1942 10.2457 17.5479 12.7735 18.2654C13.2439 18.3989 13.4791 18.4657 13.8232 18.4667C14.205 18.4678 14.7221 18.3334 15.055 18.1464C15.3551 17.9778 15.5757 17.7571 16.0171 17.3158L16.1484 17.1844C16.5913 16.7415 16.8128 16.5201 16.933 16.2823C17.1723 15.8093 17.1723 15.2508 16.933 14.7778C16.8128 14.54 16.5913 14.3186 16.1484 13.8757L15.9861 13.7133C15.6947 13.422 15.549 13.2763 15.4026 13.1811C14.8502 12.8219 14.138 12.8219 13.5856 13.1811C13.4391 13.2763 13.2934 13.422 13.0021 13.7133C12.9069 13.8086 12.8592 13.8562 12.8035 13.8961C12.6057 14.0377 12.3188 14.0866 12.0852 14.0185C12.0194 13.9994 11.9675 13.9744 11.8636 13.9245C10.6556 13.3446 9.52335 12.5539 8.52204 11.5526Z"
              stroke="currentColor"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="ml-1">Call Customer</div>
        </button>
      </div>
      <div className="border-b border-gray-200 w-full h-px mt-[18px]"></div>

      <div>
        {(requestInProgess || initialLoad) && (
          <div className="w-full flex justify-center items-center p-2">
            <div role="status">
              <svg
                aria-hidden="true"
                class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </div>
      <div className="bg-white-200 p-5 w-[60rem]">
        <div
          ref={chatRef}
          className="overflow-y-scroll h-[30rem] min-h-[30rem]"
          id="chat-container"
        >
          {loading && false ? (
            <div className="container-fluid bg-opacity-10 bg-gray-100 h-full w-full inset-0 flex items-center justify-center">
              <div className="bg-white p-5 rounded-lg shadow-lg flex justify-center">
                <svg
                  className="animate-spin h-8 w-8 text-gray-500 mx-auto"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8zm2 0a6 6 0 1010.196 4.816l-1.52-1.52A4 4 0 118 12v-2H6v2z"
                  ></path>
                </svg>
              </div>
            </div>
          ) : (
            messagelist?.messages?.map((message, index) =>
              message.type === "incoming" ? (
                <IncomingMessages
                  key={message.sid}
                  text={message.body}
                  user={userInfo}
                  date={message.date_created}
                  media={message.media}
                />
              ) : (
                <OutGoingMessages
                  key={message.sid}
                  text={message.body}
                  username={messagelist.friendly_name}
                  date={message.date_created}
                />
              )
            )
          )}
          <div ref={messagesEndRef} className="overflow-y-scroll h-[1rem]" />
        </div>

        <SendMessageInput
          onSendMessage={handleSendMessage}
          message={message}
          setMessage={setMessage}
        />
      </div>
    </div>
  );
};

export default ChatComponent;
