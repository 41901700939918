const Header = (props) => {
  return (
    <header
      style={{ height: "max-content", zIndex: "11" }}
      class="bg-white sticky top-0 w-full max-h-full h-auto"
    >
      <div class="mx-6 max-w-7xl  py-6 ">
        <div class="text-[30px] font-[500] text-[#101828]">Customers</div>
      </div>
    </header>
  );
};

export default Header;
