import "./index.css";

const ProfileStats = ({ revenueData }) => {
  return (
    <div className="px-4 mt-[32px] ">
      <div className="w-[824px] p-[24px] mr-[10px] rounded-[12px] border border-[#EAECF0] box-shadow mb-3">
        <div className="grid grid-cols-2 gap-[10px]">
          <div className="p-2  font-[500] shadow w-fit rounded-md border border-[#EAECF0] box-shadow">
            <svg
              class="h-7 w-7 text-gray-500"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              {" "}
              <path stroke="none" d="M0 0h24v24H0z" />{" "}
              <polyline points="3 17 9 11 13 15 21 7" />{" "}
              <polyline points="14 7 21 7 21 14" />
            </svg>
          </div>
          <div className="flex justify-end"></div>

          <div className="p-2  mt-4">
            <p className="font-[500] text-[14px] text-[#101828]">
              Customer lifetime revenue
            </p>
            <h1 className="text-[30px] leading-[38px] text-[#101828] mb-3 font-[500] mt-2">
              ${revenueData?.current_month_revenue}
            </h1>
          </div>
          <div className="text-right mt-5 flex justify-end">
            <div className="contianer space-x-2 mt-14 flex">
              <svg
                class="h-6 w-6 text-green-500"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                {" "}
                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                <polyline points="3 17 9 11 13 15 21 7" />{" "}
                <polyline points="14 7 21 7 21 14" />
              </svg>
              <p className="text-green-500">
                {revenueData?.revenue_change_percentage} %
              </p>
              <p className="text-gray-500 ">vs last month</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileStats;
